import React, { useState, useContext } from 'react';
import AddCustomer from './AddCustomer';
import AddFleetGroup from './AddFleetGroup';
import AddFleet from './AddFleet';
import {
  instanceOfTreeCustomerFE,
  instanceOfTreeFleetGroupFE,
  TreeCustomerFE,
  TreeFleetFE,
  TreeFleetGroupFE,
  TreeFleetUnassignedFE,
  TreeItemFE,
} from '../../../../model/frontendDataModels';
import { addCustomer, AddCustomerData } from '../../../../services/customerManipulation';
import {
  treeCustomerAddTreeCustomer,
  treeCustomerAddTreeFleet,
  treeCustomerAddTreeFleetGroup,
} from '../../../../utils/treeCustomerFunctions';
import { addFleetGroup, AddFleetGroupData } from '../../../../services/fleetGroupManipulation';
import { addFleet, AddFleetData } from '../../../../services/fleetManipulation';
import { LoginContext } from '../../../../Login';
import { AppContext } from '../../../../App';
import { BackendError } from '../../../../utils/BackendError';
import ArrangeButton from '../common/ArrangeButton';
import { Box } from '@mui/material';

type Props = {
  selectedTreeItem?: Exclude<TreeItemFE, TreeFleetUnassignedFE>;
};

export default function AddButtons({ selectedTreeItem }: Props): JSX.Element {
  const loginContext = useContext(LoginContext);
  const [addCustomerPopupOpen, setAddCustomerPopupOpen] = useState(false);
  const [addFleetGroupPopupOpen, setAddFleetGroupPopupOpen] = useState(false);
  const [addFleetPopupOpen, setAddFleetPopupOpen] = useState(false);

  const appContext = useContext(AppContext);

  async function addNewCustomer(newCustomer: AddCustomerData): Promise<void> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return;
    }
    if (appContext.rootTreeCustomer) {
      const result = await addCustomer(newCustomer, loginContext.accessToken, appContext.addBackendError);
      if (result) {
        const newTreeCustomer: TreeCustomerFE = {
          customerId: result.customerId,
          customerName: newCustomer.name,
          description: newCustomer.description,
          timezone: newCustomer.timezone,
          fleets: [],
          fleetgroups: [],
          children: [],
        };
        appContext.updateRootTreeCustomer(treeCustomerAddTreeCustomer(appContext.rootTreeCustomer, newTreeCustomer, newCustomer.parentId));
      }
    }
  }

  async function addNewFleetGroup(newFleetGroup: AddFleetGroupData): Promise<void> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return;
    }
    if (appContext.rootTreeCustomer) {
      const result = await addFleetGroup(newFleetGroup, loginContext.accessToken, appContext.addBackendError);
      if (result) {
        const newTreeFleetGroup: TreeFleetGroupFE = {
          customerId: newFleetGroup.customerId,
          fleetgroupId: result.fleetgroupId,
          fleetgroupName: newFleetGroup.name,
          description: newFleetGroup.description,
          fleetgroups: [],
          fleets: [],
        };
        appContext.updateRootTreeCustomer(
          treeCustomerAddTreeFleetGroup(appContext.rootTreeCustomer, newTreeFleetGroup, newFleetGroup.parentId)
        );
      }
    }
  }

  async function addNewFleet(newFleet: AddFleetData): Promise<boolean> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return false;
    }
    if (appContext.rootTreeCustomer) {
      const result = await addFleet(newFleet, loginContext.accessToken, appContext.addBackendError);
      if (result) {
        const newTreeFleet: TreeFleetFE = {
          customerId: newFleet.customerId,
          fleetId: result.fleetId,
          fleetName: newFleet.name,
          description: newFleet.description,
          lat: newFleet.lat,
          lng: newFleet.lng,
          activated: false,
          timezone: newFleet.timezone,
          demo: false,
          batteries: [],
          chargers: [],
          gateways: [],
        };
        appContext.updateRootTreeCustomer(treeCustomerAddTreeFleet(appContext.rootTreeCustomer, newTreeFleet, newFleet.parentId));
        return true;
      }
    }
    return false;
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
      <ArrangeButton
        title='Add Customer'
        onClick={(): void => {
          setAddCustomerPopupOpen(true);
        }}
        disabled={!(selectedTreeItem && instanceOfTreeCustomerFE(selectedTreeItem))}
      />
      <ArrangeButton
        title='Add Fleet Group'
        onClick={(): void => {
          setAddFleetGroupPopupOpen(true);
        }}
        disabled={!(selectedTreeItem && (instanceOfTreeCustomerFE(selectedTreeItem) || instanceOfTreeFleetGroupFE(selectedTreeItem)))}
      />
      <ArrangeButton
        title='Add Fleet'
        onClick={(): void => {
          setAddFleetPopupOpen(true);
        }}
        disabled={!(selectedTreeItem && (instanceOfTreeCustomerFE(selectedTreeItem) || instanceOfTreeFleetGroupFE(selectedTreeItem)))}
      />
      {selectedTreeItem && instanceOfTreeCustomerFE(selectedTreeItem) && (
        <AddCustomer
          parent={selectedTreeItem}
          open={addCustomerPopupOpen}
          close={(): void => setAddCustomerPopupOpen(false)}
          addNewCustomer={addNewCustomer}
        />
      )}
      {selectedTreeItem && (instanceOfTreeCustomerFE(selectedTreeItem) || instanceOfTreeFleetGroupFE(selectedTreeItem)) && (
        <AddFleetGroup
          parent={selectedTreeItem}
          open={addFleetGroupPopupOpen}
          close={(): void => setAddFleetGroupPopupOpen(false)}
          addNewFleetGroup={addNewFleetGroup}
        />
      )}
      {selectedTreeItem && (instanceOfTreeCustomerFE(selectedTreeItem) || instanceOfTreeFleetGroupFE(selectedTreeItem)) && (
        <AddFleet
          parent={selectedTreeItem}
          open={addFleetPopupOpen}
          close={(): void => setAddFleetPopupOpen(false)}
          addFleet={addNewFleet}
        />
      )}
    </Box>
  );
}
