import { BackendError } from '../utils/BackendError';
import { sendDeleteRequest, sendEmptyPostRequest, sendPostRequest, sendPostRequestWithReturnType } from '../utils/dataSenders';

export async function activateFleet(fleetId: string, token: string, addBackendError: (newError: BackendError) => void): Promise<boolean> {
  const url = `/fleet/${fleetId}/activate`;
  try {
    return await sendEmptyPostRequest({ url, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}

export async function deactivateFleet(fleetId: string, token: string, addBackendError: (newError: BackendError) => void): Promise<boolean> {
  const url = `/fleet/${fleetId}/deactivate`;
  try {
    return await sendEmptyPostRequest({ url, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}

export type AddFleetData = {
  name: string;
  description: string;
  isParentFleetgroup: boolean;
  parentId: string;
  lat: number | null;
  lng: number | null;
  address: string | null;
  timezone: string;
  customerId: string;
};

export function instanceOfAddFleetData(object: Record<string, unknown>): object is AddFleetData {
  return (
    'name' in object &&
    'description' in object &&
    'isParentFleetgroup' in object &&
    'parentId' in object &&
    'lat' in object &&
    'lng' in object &&
    'address' in object &&
    'timezone' in object &&
    'customerId' in object
  );
}

export type EditFleetData = {
  name: string;
  description: string;
  lat: number | null;
  lng: number | null;
  address: string | null;
  timezone: string;
};

type AddFleetReturnType = {
  fleetId: string;
};

export type FleetChangeParentData = {
  parentId: string;
  isParentFleetgroup: boolean;
};

export async function addFleet(
  data: AddFleetData,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<AddFleetReturnType | undefined> {
  const url = '/fleet/addNew';
  try {
    return await sendPostRequestWithReturnType<AddFleetData, AddFleetReturnType>({ url, data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return undefined;
  }
}

export async function editFleet(
  id: string,
  data: EditFleetData,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<boolean> {
  const url = `/fleet/${id}/edit`;
  try {
    return await sendPostRequest<EditFleetData>({ url, data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}

export async function deleteFleet(id: string, token: string, addBackendError: (newError: BackendError) => void): Promise<boolean> {
  const url = `/fleet/${id}/delete`;
  try {
    return await sendDeleteRequest({ url, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}

export async function fleetChangeParent(
  id: string,
  data: FleetChangeParentData,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<boolean> {
  const url = `/fleet/${id}/changeParent`;
  try {
    return await sendPostRequest({ url, data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}
