import { BackendError } from '../utils/BackendError';
import { sendDeleteRequest, sendPostRequest } from '../utils/dataSenders';

export async function deleteDevice(
  type: 'bms' | 'bmu' | 'charger' | 'gateway',
  mui: string,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<boolean> {
  try {
    const url = `/${type}/${mui}/delete`;
    return await sendDeleteRequest({ url, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}

export type DeviceChangeParentData = {
  parentId: string;
  customerId: string;
};

export async function deviceChangeParent(
  type: 'bms' | 'bmu' | 'charger' | 'gateway',
  mui: string | string[],
  data: DeviceChangeParentData,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<boolean> {
  try {
    const muiUrl = Array.isArray(mui) ? mui.join(',') : mui;
    const url = `/${type}/${muiUrl}/changeParent`;
    return await sendPostRequest({ url, data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}
