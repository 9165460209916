import {
  instanceOfTreeFleetUnassigned,
  TreeCustomer,
  TreeDevice,
  TreeDeviceUnassigned,
  TreeFleet,
  TreeFleetGroup,
  TreeFleetUnassigned,
  TreeGateway,
  TreeGatewayUnassigned,
} from '../model/backendDataModels';
import {
  instanceOfTreeFleetUnassignedFE,
  TreeCustomerFE,
  TreeDeviceFE,
  TreeDeviceUnassignedFE,
  TreeFleetFE,
  TreeFleetGroupFE,
  TreeFleetUnassignedFE,
  TreeGatewayFE,
  TreeGatewayUnassignedFE,
} from '../model/frontendDataModels';

function treeDeviceBeToFe(treeDevice: TreeDevice): TreeDeviceFE {
  return {
    mui: treeDevice.mui,
    deviceId: treeDevice.deviceId ? treeDevice.deviceId : '',
    deviceTag: treeDevice.deviceTag ? treeDevice.deviceTag : '',
    serialNumber: treeDevice.serialNumber,
    type: treeDevice.type,
    productCategory: treeDevice.productCategory,
    lastSeen: treeDevice.lastSeen,
    new: treeDevice.new,
    state: treeDevice.state,
    warning: treeDevice.warning,
    createdBy: treeDevice.createdBy,
    updatedBy: treeDevice.updatedBy,
  };
}

function treeGatewayBeToFe(treeDevice: TreeGateway): TreeGatewayFE {
  return {
    mui: treeDevice.mui,
    deviceId: treeDevice.deviceId ? treeDevice.deviceId : '',
    deviceTag: treeDevice.deviceTag ? treeDevice.deviceTag : '',
    serialNumber: treeDevice.serialNumber,
    type: treeDevice.type,
    productCategory: treeDevice.productCategory,
    lastSeen: treeDevice.lastSeen,
    new: treeDevice.new,
    noOfNodes: treeDevice.noOfNodes,
    state: treeDevice.state,
    createdBy: treeDevice.createdBy,
    updatedBy: treeDevice.updatedBy,
  };
}

function treeFleetBeToFe(treeFleet: TreeFleet, customerId: string): TreeFleetFE {
  return {
    customerId: customerId,
    fleetId: treeFleet.fleetId,
    fleetName: treeFleet.fleetName,
    description: treeFleet.description,
    activated: treeFleet.activated,
    lat: treeFleet.lat,
    lng: treeFleet.lng,
    timezone: treeFleet.timezone,
    demo: treeFleet.demo ? true : false,
    batteries: treeFleet.batteries ? treeFleet.batteries.map(d => treeDeviceBeToFe(d)) : [],
    chargers: treeFleet.chargers ? treeFleet.chargers.map(d => treeDeviceBeToFe(d)) : [],
    gateways: treeFleet.gateways ? treeFleet.gateways.map(d => treeGatewayBeToFe(d)) : [],
  };
}

function treeDeviceUnassignedBeToFe(treeDevice: TreeDeviceUnassigned): TreeDeviceUnassignedFE {
  return {
    mui: treeDevice.mui,
    deviceId: treeDevice.deviceId || '',
    deviceTag: treeDevice.deviceTag || '',
    serialNumber: treeDevice.serialNumber || '',
    type: treeDevice.type,
    productCategory: treeDevice.productCategory,
    lastSeen: treeDevice.lastSeen,
    new: treeDevice.new,
    state: '',
    warning: false,
    unassigned: true,
    createdBy: treeDevice.createdBy,
    updatedBy: treeDevice.updatedBy,
  };
}

function treeGatewayUnassignedBeToFe(treeDevice: TreeGatewayUnassigned): TreeGatewayUnassignedFE {
  return {
    mui: treeDevice.mui,
    deviceId: treeDevice.deviceId ? treeDevice.deviceId : '',
    deviceTag: treeDevice.deviceTag ? treeDevice.deviceTag : '',
    serialNumber: treeDevice.serialNumber,
    type: treeDevice.type,
    productCategory: treeDevice.productCategory,
    lastSeen: treeDevice.lastSeen,
    new: treeDevice.new,
    noOfNodes: treeDevice.noOfNodes,
    state: treeDevice.state,
    unassigned: true,
    createdBy: treeDevice.createdBy,
    updatedBy: treeDevice.updatedBy,
  };
}

function treeFleetUnassignedBeToFe(treeFleetUnassigned: TreeFleetUnassigned, customerId: string): TreeFleetUnassignedFE {
  return {
    fleetName: '[unassigned]',
    batteries: treeFleetUnassigned.batteries ? treeFleetUnassigned.batteries.map(d => treeDeviceUnassignedBeToFe(d)) : [],
    chargers: treeFleetUnassigned.chargers ? treeFleetUnassigned.chargers.map(d => treeDeviceUnassignedBeToFe(d)) : [],
    gateways: treeFleetUnassigned.gateways ? treeFleetUnassigned.gateways.map(d => treeGatewayUnassignedBeToFe(d)) : [],
    customerId,
  };
}

function treeFleetGroupBeToFe(treeFleetGroup: TreeFleetGroup, customerId: string): TreeFleetGroupFE {
  return {
    customerId: customerId,
    fleetgroupId: treeFleetGroup.fleetgroupId,
    fleetgroupName: treeFleetGroup.fleetgroupName,
    description: treeFleetGroup.description,
    fleets: treeFleetGroup.fleets.map(f => treeFleetBeToFe(f, customerId)),
    fleetgroups: treeFleetGroup.fleetgroups.map(fg => treeFleetGroupBeToFe(fg, customerId)),
  };
}

function treeCustomerBeToFe(treeCustomer: TreeCustomer): TreeCustomerFE {
  return {
    customerId: treeCustomer.customerId,
    customerName: treeCustomer.customerName,
    description: treeCustomer.description,
    timezone: treeCustomer.timezone,
    fleets: treeCustomer.fleets.map(f => {
      if (instanceOfTreeFleetUnassigned(f)) {
        return treeFleetUnassignedBeToFe(f, treeCustomer.customerId);
      } else {
        return treeFleetBeToFe(f, treeCustomer.customerId);
      }
    }),
    fleetgroups: treeCustomer.fleetgroups.map(fg => treeFleetGroupBeToFe(fg, treeCustomer.customerId)),
    children: treeCustomer.children.map(c => treeCustomerBeToFe(c)),
  };
}

function treeDeviceFeToBe(treeDevice: TreeDeviceFE): TreeDevice {
  return {
    mui: treeDevice.mui,
    deviceId: treeDevice.deviceId,
    deviceTag: treeDevice.deviceTag,
    serialNumber: treeDevice.serialNumber,
    type: treeDevice.type,
    productCategory: treeDevice.productCategory,
    lastSeen: treeDevice.lastSeen,
    state: treeDevice.state,
    warning: treeDevice.warning,
    new: treeDevice.new,
    createdBy: treeDevice.createdBy,
    updatedBy: treeDevice.updatedBy,
  };
}

function treeGatewayFeToBe(treeGatewayFe: TreeGatewayFE): TreeGateway {
  return {
    mui: treeGatewayFe.mui,
    serialNumber: treeGatewayFe.serialNumber,
    productCategory: treeGatewayFe.productCategory,
    type: treeGatewayFe.type,
    deviceId: treeGatewayFe.deviceId,
    deviceTag: treeGatewayFe.deviceTag,
    lastSeen: treeGatewayFe.lastSeen,
    noOfNodes: treeGatewayFe.noOfNodes,
    state: treeGatewayFe.state,
    new: treeGatewayFe.new,
    createdBy: treeGatewayFe.createdBy,
    updatedBy: treeGatewayFe.updatedBy,
  };
}

function treeFleetFeToBe(treeFleet: TreeFleetFE): TreeFleet {
  const backendTreeFleet: TreeFleet = {
    fleetId: treeFleet.fleetId,
    fleetName: treeFleet.fleetName,
    description: treeFleet.description,
    activated: treeFleet.activated,
    lat: treeFleet.lat,
    lng: treeFleet.lng,
    address: '',
    timezone: treeFleet.timezone,
    batteries: treeFleet.batteries ? treeFleet.batteries.map(d => treeDeviceFeToBe(d)) : [],
    chargers: treeFleet.chargers ? treeFleet.chargers.map(d => treeDeviceFeToBe(d)) : [],
    gateways: treeFleet.gateways ? treeFleet.gateways.map(d => treeGatewayFeToBe(d)) : [],
  };
  if (treeFleet.demo) {
    backendTreeFleet.demo = true;
  }
  return backendTreeFleet;
}

function treeDeviceUnassignedFeToBe(treeDevice: TreeDeviceUnassignedFE): TreeDeviceUnassigned {
  return {
    mui: treeDevice.mui,
    type: treeDevice.type,
    productCategory: treeDevice.productCategory,
    new: treeDevice.new,
    serialNumber: treeDevice.serialNumber,
    deviceId: treeDevice.deviceId,
    deviceTag: treeDevice.deviceTag,
    lastSeen: treeDevice.lastSeen,
    createdBy: treeDevice.createdBy,
    updatedBy: treeDevice.updatedBy,
  };
}

function treeGatewayUnassignedFeToBe(treeDevice: TreeGatewayUnassignedFE): TreeGatewayUnassigned {
  return {
    mui: treeDevice.mui,
    type: treeDevice.type,
    new: treeDevice.new,
    serialNumber: treeDevice.serialNumber,
    productCategory: treeDevice.productCategory,
    lastSeen: treeDevice.lastSeen,
    state: treeDevice.state,
    noOfNodes: treeDevice.noOfNodes,
    createdBy: treeDevice.createdBy,
    updatedBy: treeDevice.updatedBy,
  };
}

function treeFleetUnassignedFeToBe(treeFleetUnassigned: TreeFleetUnassignedFE): TreeFleetUnassigned {
  return {
    fleetName: '[unassigned]',
    batteries: treeFleetUnassigned.batteries ? treeFleetUnassigned.batteries.map(d => treeDeviceUnassignedFeToBe(d)) : [],
    chargers: treeFleetUnassigned.chargers ? treeFleetUnassigned.chargers.map(d => treeDeviceUnassignedFeToBe(d)) : [],
    gateways: treeFleetUnassigned.gateways ? treeFleetUnassigned.gateways.map(d => treeGatewayUnassignedFeToBe(d)) : [],
  };
}

function treeFleetGroupFeToBe(treeFleetGroup: TreeFleetGroupFE): TreeFleetGroup {
  return {
    fleetgroupId: treeFleetGroup.fleetgroupId,
    fleetgroupName: treeFleetGroup.fleetgroupName,
    description: treeFleetGroup.description,
    fleets: treeFleetGroup.fleets.map(f => treeFleetFeToBe(f)),
    fleetgroups: treeFleetGroup.fleetgroups.map(fg => treeFleetGroupFeToBe(fg)),
  };
}

function treeCustomerFeToBe(treeCustomer: TreeCustomerFE): TreeCustomer {
  return {
    customerId: treeCustomer.customerId,
    customerName: treeCustomer.customerName,
    description: treeCustomer.description,
    timezone: treeCustomer.timezone,
    fleets: treeCustomer.fleets.map(f => {
      if (instanceOfTreeFleetUnassignedFE(f)) {
        return treeFleetUnassignedFeToBe(f);
      } else {
        return treeFleetFeToBe(f);
      }
    }),
    fleetgroups: treeCustomer.fleetgroups.map(fg => treeFleetGroupFeToBe(fg)),
    children: treeCustomer.children.map(c => treeCustomerFeToBe(c)),
  };
}

export function convertTreeCustomerToFE(backendTree: TreeCustomer | undefined): TreeCustomerFE | undefined {
  return backendTree ? treeCustomerBeToFe(backendTree) : undefined;
}

export function convertTreeCustomerFromFE(rootTreeCustomer: TreeCustomerFE): TreeCustomer {
  return treeCustomerFeToBe(rootTreeCustomer);
}
