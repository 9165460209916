import { sendGetRequest } from '../utils/dataSenders';

export async function generateLogExport(
  selectedType: string,
  selectedId: string,
  startDate: Date,
  endDate: Date,
  token: string,
  timezone: string
): Promise<Response> {
  const url = `/report/export/${selectedType}/${selectedId}`;
  const args = {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    timezone: timezone,
  };
  return sendGetRequest({ url: url, args: args, token: token });
}

export async function generateLogTypeExport(
  selectedType: string,
  selectedId: string,
  logType: string,
  startDate: Date,
  endDate: Date,
  token: string,
  timezone: string
): Promise<Response> {
  const url = `/${selectedType}/${selectedId}/${logType}/export`;
  const args = {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    timezone: timezone,
  };
  return sendGetRequest({ url: url, args: args, token: token });
}

export async function generateConfigurationExport(
  selectedType: string,
  selectedId: string,
  token: string,
  timezone: string
): Promise<Response> {
  const url = `/${selectedType}/${selectedId}/config/export`;
  const args = {
    timezone: timezone,
  };
  return sendGetRequest({ url: url, args: args, token: token });
}
