import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import SortableList from '../../../common/SortableList';
import { COMPONENT_PADDING } from '../../../../../themes/theme';
import LabelledInput from './LabelledInput';
import LoadingIndicator from '../../../../common/LoadingIndicator';
import { FieldsAndValues, instanceOfLogData, LogData } from '../../../../../model/backendDataModels';
import { addDays, subMonths, subDays, format } from 'date-fns';
import InputComponent from '../../../../common/InputComponent';
import InputRow from './InputRow';
import { SWRResponse } from 'swr';
import { BackendError } from '../../../../../utils/BackendError';
import { Pagination, Typography } from '@mui/material';
import { CommonLogSettings } from '../../../../../reducers/Reducer';
import ValidatedDatePicker from './ValidatedDatePicker';
import { AppContext } from '../../../../../App';
import { sortData } from '../../../../../utils/dataSortUtils';
import moment from 'moment-timezone';
import Store from '../../../../../store/Store';
import { utcToZonedTime } from 'date-fns-tz';
import DefaultLoadingButton from '../../../../common/DefaultLoadingButton';
import { useDownloadFile } from '../../../../../dataHooks/useDownloadFile';
import { LoginContext } from '../../../../../Login';
import { generateLogTypeExport } from '../../../../../services/generateLogExport';

const PAGE_SIZE = 100;

type VisibilityType = 'basic' | 'advanced';
type HeaderNameAndVisibilityType = {
  header: string;
  visibility: VisibilityType;
};
export type HeaderNamesAndVisibilitiesObjectType = Record<string, HeaderNameAndVisibilityType>;

function getHeaders(logData: LogData, visibility: VisibilityType): string[] {
  let headers = logData.basic.fields;

  if (visibility !== 'basic') {
    headers = logData.basic.fields.concat(logData.advanced.fields);
  }

  return headers.map(field => field.name);
}

function mergeObjectData(fieldsAndValues: FieldsAndValues, timezone: string): FieldsAndValues {
  const mergedValues: string[][] = [];

  fieldsAndValues.values.forEach(value => {
    const values: string[] = [];

    fieldsAndValues.fields.forEach((field, index) => {
      if (field.type === 'datetime') {
        const date = moment(value[index], moment.ISO_8601).tz(timezone);
        const dateString = date.format('YYYY-MM-DD HH:mm:ss');

        values.push(dateString);
      } else if (field.type === 'boolean') {
        values.push(value[index] ? 'Active' : 'Inactive');
      } else if (field.type === 'number') {
        values.push(value[index].toString());
      } else {
        values.push(value[index]);
      }
    });

    mergedValues.push(values);
  });

  return {
    fields: fieldsAndValues.fields,
    values: mergedValues,
  };
}

function getRows(logData: LogData, visibility: VisibilityType, timezone: string): string[][] {
  const mergedData: LogData = {
    basic: mergeObjectData(logData.basic, timezone),
    advanced: mergeObjectData(logData.advanced, timezone),
    other: mergeObjectData(logData.other, timezone),
  };

  if (visibility === 'basic') {
    return mergedData.basic.values;
  }

  return mergedData.basic.values.map(function (e, i) {
    return e.concat(mergedData.advanced.values[i]);
  });
}

type CommonLogTabProps = {
  mui: string;
  serialNumber: string;
  productCategory: 'bms' | 'bmu' | 'charger';
  logType: 'history' | 'event' | 'protective';
  dataHook: (startDate: Date, endDate: Date) => SWRResponse<LogData, BackendError> | SWRResponse<FieldsAndValues, BackendError>;
  logSettings?: CommonLogSettings;
  updateLogSettings: (settings: CommonLogSettings) => void;
};

export default function CommonLogTab(props: CommonLogTabProps): JSX.Element {
  const { state } = useContext(Store);
  const now = utcToZonedTime(new Date(), state.timezone);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [startDate, setStartDate] = useState<Date>(props.logSettings ? props.logSettings.startDate : subMonths(now, 1));
  const [endDate, setEndDate] = useState<Date>(props.logSettings ? props.logSettings.endDate : now);
  const [page, setPage] = useState(1);
  const [sortCol, setSortCol] = useState<number | undefined>();
  const [sortAsc, setSortAsc] = useState<boolean | undefined>();
  const [generating, setGenerating] = useState(false);

  const appContext = useContext(AppContext);
  const loginContext = useContext(LoginContext);

  const { data: logData, error } = props.dataHook(startDate, endDate);

  useEffect(() => {
    setPage(1);
  }, [logData]);

  useEffect(() => {
    props.updateLogSettings({ startDate, endDate });
  }, [startDate, endDate]);

  if (error) {
    appContext.addBackendError(error);
  }

  const headers = logData
    ? instanceOfLogData(logData)
      ? getHeaders(logData, showAdvanced ? 'advanced' : 'basic')
      : logData.fields.map(field => field.name)
    : undefined;
  const allRows = logData
    ? instanceOfLogData(logData)
      ? getRows(logData, showAdvanced ? 'advanced' : 'basic', state.timezone)
      : mergeObjectData(logData, state.timezone).values
    : undefined;

  const pages = allRows ? Math.ceil(allRows.length / PAGE_SIZE) : 1;
  const rows = allRows ? sortData<string>(allRows, sortCol, sortAsc).slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE) : undefined;

  const preDownloading = (): void => setGenerating(true);
  const postDownloading = (): void => setGenerating(false);
  const onErrorDownloadFile = (): void => {
    setGenerating(false);
  };
  const getFileName = (): string => {
    return `GET Logs ${props.logType} ${props.serialNumber} ${format(startDate, 'yyyy-MM-dd')}-${format(endDate, 'yyyy-MM-dd')}.xlsx`;
  };
  const downloadFile = (): Promise<Response> => {
    const token = loginContext.accessToken || '';

    return generateLogTypeExport(props.productCategory, props.mui, props.logType, startDate, endDate, token, state.timezone);
  };
  const { download } = useDownloadFile({
    apiDefinition: downloadFile,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName,
  });

  let noData = false;
  if (logData) {
    if (instanceOfLogData(logData) && logData.basic.values.length === 0) {
      noData = true;
    }
    if (!instanceOfLogData(logData) && logData.values.length === 0) {
      noData = true;
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderTop: '2px solid black',
        padding: `${COMPONENT_PADDING}px`,
        height: '100%',
      }}
    >
      <InputRow>
        <LabelledInput
          label='Start date'
          input={
            <ValidatedDatePicker
              value={startDate}
              updateValue={(date: Date): void => {
                setStartDate(date);
              }}
              maxDate={subDays(endDate, 1)}
            />
          }
        />
        <LabelledInput
          label='End date'
          input={
            <ValidatedDatePicker
              value={endDate}
              updateValue={(date: Date): void => {
                setEndDate(date);
              }}
              minDate={addDays(startDate, 1)}
              maxDate={now}
            />
          }
        />

        {logData && !error && instanceOfLogData(logData) && (
          <InputComponent label='Advanced'>
            <Switch onChange={(event, checked): void => setShowAdvanced(checked)} color='info' />
          </InputComponent>
        )}

        {!error && (
          <InputComponent label=''>
            <DefaultLoadingButton
              sx={{
                height: '40px',
              }}
              loading={generating}
              onClick={download}
              disabled={pages === 0}
            >
              Export data
            </DefaultLoadingButton>
          </InputComponent>
        )}
      </InputRow>
      {noData && <Typography variant='sleekHeader'>No Data</Typography>}
      {rows && !noData && headers && !error && (
        <SortableList
          headers={headers}
          data={rows}
          changeDataParameters={(sortCol: number, sortAsc: boolean): void => {
            setSortCol(sortCol);
            setSortAsc(sortAsc);
          }}
        />
      )}
      {(!rows || !headers) && !noData && !error && <LoadingIndicator />}

      {logData && !noData && !error && page > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Pagination
            count={pages}
            page={page}
            onChange={(event, page): void => {
              setPage(page);
            }}
          />
        </Box>
      )}
    </Box>
  );
}
