export type TreeDeviceFE = {
  mui: string;
  deviceId: string;
  deviceTag: string;
  serialNumber: string;
  type: string;
  productCategory: 'bmu' | 'bms' | 'charger';
  lastSeen: string;
  new: boolean;
  state: string;
  warning: boolean;
  createdBy: string;
  updatedBy: string;
};

export function instanceOfTreeDeviceFE(object: Record<string, unknown>): object is TreeDeviceFE {
  return (
    'mui' in object &&
    'deviceId' in object &&
    'deviceTag' in object &&
    'serialNumber' in object &&
    'type' in object &&
    'productCategory' in object &&
    (object.productCategory === 'bmu' || object.productCategory === 'bms' || object.productCategory === 'charger') &&
    'lastSeen' in object &&
    'new' in object &&
    'state' in object &&
    'warning' in object
  );
}

export type TreeGatewayFE = {
  mui: string;
  deviceId: string;
  deviceTag: string;
  serialNumber: string;
  type: 'Cirrus gateway';
  productCategory: 'gateway';
  lastSeen: string;
  new: boolean;
  state: 'Online' | 'Offline';
  noOfNodes: number;
  createdBy: string;
  updatedBy: string;
};

export function instanceOfTreeGatewayFE(object: Record<string, unknown>): object is TreeGatewayFE {
  return (
    'mui' in object &&
    'deviceId' in object &&
    'deviceTag' in object &&
    'serialNumber' in object &&
    'type' in object &&
    'productCategory' in object &&
    object.productCategory === 'gateway' &&
    'lastSeen' in object &&
    'new' in object &&
    'state' in object &&
    'noOfNodes' in object
  );
}

export type TreeDeviceUnassignedFE = TreeDeviceFE & {
  unassigned: true;
};

export function instanceOfTreeDeviceUnassignedFE(object: Record<string, unknown>): object is TreeDeviceUnassignedFE {
  return instanceOfTreeDeviceFE(object) && 'unassigned' in object;
}

export type TreeGatewayUnassignedFE = TreeGatewayFE & {
  unassigned: true;
};

export function instanceOfTreeGatewayUnassignedFE(object: Record<string, unknown>): object is TreeGatewayUnassignedFE {
  return instanceOfTreeGatewayFE(object) && 'unassigned' in object;
}

export type TreeFleetFE = {
  customerId: string;
  fleetId: string;
  fleetName: string;
  description: string;
  activated: boolean;
  lat: number | null;
  lng: number | null;
  timezone: string;
  demo: boolean;
  batteries: TreeDeviceFE[];
  chargers: TreeDeviceFE[];
  gateways: TreeGatewayFE[];
};

export function instanceOfTreeFleetFE(object: Record<string, unknown>): object is TreeFleetFE {
  return (
    'customerId' in object &&
    'fleetId' in object &&
    'fleetName' in object &&
    object.fleetName !== '[unassigned]' &&
    'description' in object &&
    'activated' in object &&
    'lat' in object &&
    'lng' in object &&
    'timezone' in object &&
    'demo' in object &&
    'batteries' in object &&
    'chargers' in object &&
    'gateways' in object
  );
}

export type TreeFleetUnassignedFE = {
  fleetName: '[unassigned]';
  batteries: TreeDeviceUnassignedFE[];
  chargers: TreeDeviceUnassignedFE[];
  gateways: TreeGatewayUnassignedFE[];
  customerId: string;
};

export function instanceOfTreeFleetUnassignedFE(object: Record<string, unknown>): object is TreeFleetUnassignedFE {
  return (
    'fleetName' in object &&
    object.fleetName === '[unassigned]' &&
    'batteries' in object &&
    'chargers' in object &&
    'gateways' in object &&
    'customerId' in object
  );
}

export type TreeFleetGroupFE = {
  fleetgroupId: string;
  fleetgroupName: string;
  description: string;
  fleets: TreeFleetFE[];
  fleetgroups: TreeFleetGroupFE[];
  customerId: string;
};

export function instanceOfTreeFleetGroupFE(object: Record<string, unknown>): object is TreeFleetGroupFE {
  return (
    'fleetgroupId' in object &&
    'fleetgroupName' in object &&
    'description' in object &&
    'fleets' in object &&
    'fleetgroups' in object &&
    'customerId' in object
  );
}

export type TreeCustomerFE = {
  customerId: string;
  customerName: string;
  description: string;
  timezone: string;
  fleets: (TreeFleetFE | TreeFleetUnassignedFE)[];
  fleetgroups: TreeFleetGroupFE[];
  children: TreeCustomerFE[];
};

export function instanceOfTreeCustomerFE(object: Record<string, unknown>): object is TreeCustomerFE {
  return (
    'customerId' in object &&
    'customerName' in object &&
    'description' in object &&
    'fleets' in object &&
    'fleetgroups' in object &&
    'children' in object
  );
}

export type TreeItemFE =
  | TreeCustomerFE
  | TreeFleetGroupFE
  | TreeFleetFE
  | TreeFleetUnassignedFE
  | TreeDeviceFE
  | TreeDeviceUnassignedFE
  | TreeGatewayFE
  | TreeGatewayUnassignedFE;

export function compareItems(item1: TreeItemFE, item2: TreeItemFE): boolean {
  if (instanceOfTreeCustomerFE(item1) && instanceOfTreeCustomerFE(item2)) {
    return item1.customerId === item2.customerId;
  }
  if (instanceOfTreeFleetGroupFE(item1) && instanceOfTreeFleetGroupFE(item2)) {
    return item1.fleetgroupId === item2.fleetgroupId;
  }
  if (instanceOfTreeFleetFE(item1) && instanceOfTreeFleetFE(item2)) {
    return item1.fleetId === item2.fleetId;
  }
  if (instanceOfTreeFleetUnassignedFE(item1) && instanceOfTreeFleetUnassignedFE(item2)) {
    return item1.customerId === item2.customerId;
  }
  if (instanceOfTreeDeviceFE(item1) && instanceOfTreeDeviceFE(item2)) {
    return item1.mui === item2.mui;
  }
  if (instanceOfTreeDeviceUnassignedFE(item1) && instanceOfTreeDeviceUnassignedFE(item2)) {
    return item1.mui === item2.mui;
  }
  if (instanceOfTreeGatewayFE(item1) && instanceOfTreeGatewayFE(item2)) {
    return item1.mui === item2.mui;
  }
  if (instanceOfTreeGatewayUnassignedFE(item1) && instanceOfTreeGatewayUnassignedFE(item2)) {
    return item1.mui === item2.mui;
  }

  return false;
}
