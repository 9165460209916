import React, { useContext } from 'react';
import { SWRResponse } from 'swr';
import { useDeviceEventLogs } from '../../../../dataHooks/deviceHooks';
import { FieldsAndValues } from '../../../../model/backendDataModels';
import { CommonLogSettings } from '../../../../reducers/Reducer';
import Store from '../../../../store/Store';
import { BackendError } from '../../../../utils/BackendError';
import CommonLogTab from './common/CommonLogTab';

type EventLogTabProps = {
  mui: string;
  serialNumber: string;
  productCategory: 'bms' | 'bmu' | 'charger';
};

export default function EventLogTab({ mui, serialNumber, productCategory }: EventLogTabProps): JSX.Element {
  const { state, dispatch } = useContext(Store);

  function getEventLogDataHook(from: Date, to: Date): SWRResponse<FieldsAndValues, BackendError> {
    return useDeviceEventLogs(productCategory, mui, from, to);
  }

  return (
    <CommonLogTab
      mui={mui}
      serialNumber={serialNumber}
      productCategory={productCategory}
      logType='event'
      dataHook={getEventLogDataHook}
      logSettings={state.logFilesSettings.eventLogSettings}
      updateLogSettings={(settings: CommonLogSettings): void => {
        dispatch({
          type: 'SET_EVENT_LOG_SETTINGS',
          payload: settings,
        });
      }}
    />
  );
}
