import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import { timeZonesNames } from '@vvo/tzdb';
import AddEditFleetMap, { LngLatType } from './AddEditFleetMap';
import InputItem from '../../../../common/InputItem';
import {
  instanceOfTreeCustomerFE,
  instanceOfTreeFleetGroupFE,
  TreeCustomerFE,
  TreeFleetFE,
  TreeFleetGroupFE,
} from '../../../../../model/frontendDataModels';
import InputLongText from '../../../../common/InputLongText';
import AddEditDialog from '../AddEditDialog';
import getTimeZone from '../../../../../services/getTimeZone';
import { AddFleetData, EditFleetData } from '../../../../../services/fleetManipulation';
import InputAutocomplete from './InputAutocomplete';
import reverseGeocoding from '../../../../../services/reverseGeocoding';

function prettifyLocation(location: LngLatType): string {
  const obj: { lat: number; lng: number } = JSON.parse(JSON.stringify(location));
  const value = `Lat: ${obj.lat.toFixed(6)}, Lng: ${obj.lng.toFixed(6)}`;
  return value;
}

type AddEditFleetProps = {
  treeFleet?: TreeFleetFE;
  parent: TreeCustomerFE | TreeFleetGroupFE;
  mode: 'Add' | 'Edit';
  open: boolean;
  close: () => void;
  addFleet?: (fleetData: AddFleetData) => Promise<boolean>;
  editFleet?: (treeFleet: TreeFleetFE, fleetData: EditFleetData) => Promise<boolean>;
};

export default function AddEditFleet({ treeFleet, parent, mode, open, close, addFleet, editFleet }: AddEditFleetProps): JSX.Element {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [lat, setLat] = useState<number | null>(null);
  const [lng, setLng] = useState<number | null>(null);
  const [location, setLocation] = useState<LngLatType | undefined>(undefined);
  const [address, setAddress] = useState<string | null>(null);
  const [timeZoneFromLocation, setTimeZoneFromLocation] = useState<string | undefined>(undefined);
  const [timeZoneFromList, setTimeZoneFromList] = useState<string | null>(treeFleet && treeFleet.timezone ? treeFleet.timezone : null);

  async function updateTimeZone(location: LngLatType): Promise<void> {
    const timezone = await getTimeZone(location);
    if (timezone) {
      setTimeZoneFromLocation(timezone);
      setTimeZoneFromList(timezone);
    }
  }

  function resetValues(): void {
    if (treeFleet) {
      setName(treeFleet.fleetName);
      setDescription(treeFleet.description);
      setLocation(treeFleet.lat && treeFleet.lng ? { lat: treeFleet.lat, lng: treeFleet.lng } : undefined);
      setTimeZoneFromList(treeFleet && treeFleet.timezone ? treeFleet.timezone : null);
    } else {
      setName('');
      setDescription('');
      setLocation(undefined);
      setTimeZoneFromList(null);
    }
  }

  useEffect(() => {
    resetValues();
  }, [treeFleet]);

  async function updateAddress(location: LngLatType): Promise<void> {
    const ad = await reverseGeocoding(location);
    setAddress(ad);
  }

  useEffect(() => {
    if (location) {
      updateTimeZone(location);
      setLat(location.lat);
      setLng(location.lng);
      updateAddress(location);
    } else {
      setTimeZoneFromLocation(undefined);
      setLat(null);
      setLng(null);
      setAddress(null);
    }
  }, [location]);

  return (
    <AddEditDialog
      title='fleet'
      parentName={instanceOfTreeCustomerFE(parent) ? parent.customerName : parent.fleetgroupName}
      open={open}
      close={async (ok: boolean): Promise<void> => {
        if (ok) {
          if (mode === 'Add' && addFleet) {
            const addData: AddFleetData = {
              name,
              description,
              isParentFleetgroup: instanceOfTreeFleetGroupFE(parent),
              parentId: instanceOfTreeFleetGroupFE(parent) ? parent.fleetgroupId : parent.customerId,
              lat,
              lng,
              address,
              timezone: timeZoneFromLocation ? timeZoneFromLocation : timeZoneFromList ? timeZoneFromList : '',
              customerId: parent.customerId,
            };
            const result = await addFleet(addData);
            if (!result) {
              resetValues();
            }
          } else if (mode === 'Edit' && editFleet && treeFleet) {
            const result = await editFleet(treeFleet, {
              name,
              description,
              lat,
              lng,
              address,
              timezone: timeZoneFromLocation ? timeZoneFromLocation : timeZoneFromList ? timeZoneFromList : '',
            });
            if (!result) {
              resetValues();
            }
          }
        } else {
          resetValues();
        }
        close();
      }}
      mode={mode}
      okDisabled={name === '' || !((location && timeZoneFromLocation) || timeZoneFromList)}
      size='lg'
    >
      <Stack direction='row' spacing={2} sx={{ height: '500px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '35%', height: '100%' }}>
          <InputItem label='Name' value={name} updateValue={setName} />
          <InputLongText label='Description' value={description} updateValue={setDescription} />
          {address && <Typography variant='tableText'>{`Address: ${address}`}</Typography>}
          {location && <Typography variant='tableText'>{prettifyLocation(location)}</Typography>}
          {timeZoneFromLocation && <Typography variant='tableText'>{timeZoneFromLocation}</Typography>}
          <InputAutocomplete
            label='Time Zone'
            value={timeZoneFromList || timeZonesNames[0]}
            items={timeZonesNames}
            updateValue={setTimeZoneFromList}
            disabled={location !== undefined ? true : undefined}
          />
        </Box>
        <AddEditFleetMap
          fleetLocation={location}
          updateLocation={(location?: LngLatType): void => {
            setLocation(location);
          }}
        />
      </Stack>
    </AddEditDialog>
  );
}
